import React, { useState, useEffect } from 'react';
import { FaArrowUp } from 'react-icons/fa';

import './ScrollToTopArrow.css';

// SCROLL TO TOP ARROW

const ScrollToTopArrow = () => {
  const [showArrow, setShowArrow] = useState(false);

  useEffect(() => {
    let isScrolling;
    const handleScroll = () => {
      setShowArrow(false);
      clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        if (window.scrollY > 0) {
          setShowArrow(true);
        }
      }, 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      {showArrow && (
        <a href="#top" className="scroll-to-top-arrow" onClick={scrollToTop}>
          <FaArrowUp />
        </a>
      )}
    </div>
  );
};

export default ScrollToTopArrow;
