import React from 'react';
import { images } from '../../constants';
import './AboutUs.css';

// ABOUT

const AboutUs = () => (
  <div
    className="app__aboutus app__bg flex__center section__padding"
    id="about"
  >
    <div className="app__aboutus-overlay flex__center ">
      <img src={images.G} alt="g letter" />
    </div>

    {/* ABOUT US SIDE */}

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">À propos de nous</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">
          Chez Guich & Gourmet, notre passion pour la cuisine et les saveurs se
          reflète dans chaque assiette et chaque verre. Nous vous proposons une
          expérience culinaire unique, où les plats raffinés côtoient une
          sélection de cocktails artisanaux, créés avec soin par nos mixologues.
          Que vous veniez pour savourer un dîner ou pour découvrir nos cocktails
          signatures, nous vous accueillons dans une ambiance chaleureuse et
          conviviale, avec une équipe dévouée à rendre chaque moment
          inoubliable.
        </p>
        {/* <button type="button" className="custom__button">
          Know More
        </button> */}
      </div>
      <div className="app__aboutus-content_knife flex__center">
        <img src={images.knife} alt="about_knife" />
      </div>

      {/* OUR HISTORY SIDE */}

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Notre histoire</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">
          Fondé en 2014 par Guiche Nicolas, Guich & Gourmet est le fruit d'une
          passion pour la cuisine et l'hospitalité. Depuis nos débuts modestes,
          nous avons évolué tout en restant fidèles à notre engagement pour une
          expérience culinaire exceptionnelle. Aujourd'hui, nous sommes fiers de
          proposer un lieu où tradition et innovation se rencontrent, offrant à
          chaque visiteur un moment de partage et de découverte.
        </p>
        {/* <button type="button" className="custom__button">
          Know More
        </button> */}
      </div>
    </div>
  </div>
);

export default AboutUs;
