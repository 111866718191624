import React, { useEffect, useRef, useState } from 'react';
import {
  BsInstagram,
  BsArrowLeftShort,
  BsArrowRightShort,
} from 'react-icons/bs';
import { images } from '../../constants';
import './Gallery.css';
import { SubHeading } from '../../components';
import ImageModal from '../../components/ImageModal/ImageModal';

// GALLERY

const galleryImages = [
  images.gallery01,
  images.gallery02,
  images.gallery03,
  images.gallery04,
];
const modalImages = [
  images.gallery01,
  images.gallery02,
  images.gallery03,
  images.gallery04,
  images.gallery05,
  images.gallery06,
  images.gallery07,
  images.gallery08,
  images.gallery09,
  images.gallery10,
  images.gallery11,
];

const Gallery = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const scrollRef = useRef(null);

  // Ajouter ou retirer la classe no-scroll du body en fonction de l'état du modal
  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isModalOpen]);

  const scroll = (direction) => {
    const { current } = scrollRef;
    if (direction === 'left') {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
    <div className="app__gallery flex__center">
      <div className="app_gallery_content">
        <SubHeading title="Instagram" />
        <h1 className="headtext__cormorant">Galerie de photos</h1>
        <p
          className="__opensans"
          style={{ color: '#545454', marginTop: '2rem' }}
        >
          Plongez dans l’ambiance chaleureuse de Guich & Gourmet et laissez-vous
          inspirer par nos plats raffinés et nos cocktails artisanaux. Chaque
          photo capture un instant de notre passion pour la cuisine et le
          service.
        </p>
        <button
          type="button"
          className="custom__button"
          onClick={() => setIsModalOpen(true)}
        >
          Voir plus
        </button>
      </div>
      <div className="app__gallery-images">
        <div className="app__gallery-images_container" ref={scrollRef}>
          {galleryImages.map((image, index) => (
            <div
              className="app__gallery-images_card flex__center"
              key={`gallery_image-${index + 1}`}
            >
              <img src={image} alt="gallery" />
              <BsInstagram className="gallery__image-icon" />
            </div>
          ))}
        </div>
        <div className="app__gallery-images_arrows">
          <BsArrowLeftShort
            className="gallery__arrow-icon"
            onClick={() => scroll('left')}
          />
          <BsArrowRightShort
            className="gallery__arrow-icon"
            onClick={() => scroll('right')}
          />
        </div>
      </div>

      <ImageModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        images={modalImages}
      />
    </div>
  );
};

export default Gallery;
