import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram } from 'react-icons/fi';
import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';
import './Footer.css';
import ScrollToTopArrow from '../../components/Footer/ScrollToTopArrow';

const Footer = () => (
  <div className="app__footer section__padding">
    <FooterOverlay />
    <Newsletter />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h1 className="app__footer-headtext">Contactez-nous</h1>
        <p className="p__opensans">
          Guich & Gourmet 123 Rue des Saveurs 75001 Paris France
        </p>
        <p className="p__opensans">01 01 01 01 01</p>
        <p className="p__opensans">01 02 02 02 02</p>
      </div>
      <div className="app__footer-links_logo">
        <img src={images.guichgourmet} alt="footer_logo" />
        <p className="p__opensans">
          "La meilleure façon de se retrouver est de se perdre au service de
          autres"
        </p>
        <img
          src={images.spoon}
          alt="spoon"
          className="spoon__img"
          style={{ marginTop: 15 }}
        />
        <div className="app__footer-links_icons">
          <FiFacebook />
          <FiTwitter />
          <FiInstagram />
        </div>
      </div>

      <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Heures d'ouverture</h1>
        <p className="p__opensans">Lundi-Vendredi:</p>
        <p className="p__opensans">10h00 - 23h00 </p>
        <p className="p__opensans">Samedi - Dimanche:</p>
        <p className="p__opensans">10h00 - 01h00</p>
        <ScrollToTopArrow />
      </div>
    </div>
    <div className="footer__copyright">
      <p className="p__opensans">2024 Guich & Gourmet. Tous droits réservés.</p>
    </div>
  </div>
);

export default Footer;
