import React from 'react';
import './ImageModal.css';

const ImageModal = ({ isOpen, onClose, images }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          X
        </button>
        <div className="modal-images">
          {images.map((image, index) => (
            <div className="modal-image" key={index}>
              <img src={image} alt={`Modal ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
