import React from 'react';
import { images } from '../../constants';
import './Chef.css';
import { SubHeading } from '../../components';

// CHEF

const Chef = () => (
  <div className="app__bg app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.chef} alt="chef" />
    </div>
    <div className="app__wrapper_info">
      <SubHeading title="Le mot du chef" />
      <h1 className="headtext__cormorant">Ce en quoi nous croyons</h1>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <img src={images.quote} alt="quote" />
          <p className="p__opensans">
            Chez Guich & Gourmet, nous croyons en l'authenticité, le partage, et
            l'harmonie.
          </p>
        </div>
        <p className="p__opensans">
          Nous nous engageons à offrir des plats et des cocktails de qualité,
          tout en respectant des pratiques durables. Nous valorisons chaque
          client en créant une expérience mémorable grâce à un service
          chaleureux et attentionné.
        </p>
      </div>
      <div className="app__chef-sign">
        <p>Guiche Nicolas</p>
        <p className="p__opensans">Chef & Fondateur</p>
        <img src={images.sign} alt="sign" />
      </div>
    </div>
  </div>
);

export default Chef;
