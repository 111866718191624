import React, { useEffect, useState } from 'react';
import './NotificationBanner.css';

const NotificationBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const hasSeenNotification = localStorage.getItem('hasSeenNotification');
    if (!hasSeenNotification) {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem('hasSeenNotification', 'true');
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="notification-overlay">
      <div className="notification-content">
        <h2>Information</h2>
        <p>
          Ce site est un projet fictif, destiné uniquement à des fins de
          démonstration.
        </p>
        <button onClick={handleClose} className="custom__button">
          Fermer
        </button>
      </div>
    </div>
  );
};

export default NotificationBanner;
