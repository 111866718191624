import React from 'react';
import { images, data } from '../../constants';
import './SpecialMenu.css';
import { MenuItem, SubHeading } from '../../components';

// SPECIAL MENU

const SpecialMenu = () => (
  <div className="app__specialMenu flex__center section__padding" id="menu">
    <div className="app__specialMenu-title">
      <SubHeading title="Un menu qui correspond à votre palais" />
      <h1 className="headtext__cormorant">Nos spécialités</h1>
    </div>
    <div className="app__specialMenu-menu">
      {/* SPECIAL MENU WINE BEER LEFT SIDE */}

      <div className="app__specialMenu-menu_wine flex__center">
        <p className="app__specialMenu-menu_heading">Vin & Bière</p>
        <div className="app__specialMenu_menu_items">
          {data.wines.map((wine, index) => (
            <MenuItem
              key={wine.title + index}
              title={wine.title}
              price={wine.price}
              tags={wine.tags}
            />
          ))}
        </div>
      </div>
      <div className="app__specialMenu-menu_img">
        <img src={images.cocktail} alt="menu img" />
      </div>

      {/* SPECIAL MENU COCKTAILS RIGHT SIDE */}

      <div className="app__specialMenu-menu_cocktails flex__center">
        <p className="app__specialMenu-menu_heading">Cocktails</p>
        <div className="app__specialMenu_menu_items">
          {data.cocktails.map((cocktail, index) => (
            <MenuItem
              key={cocktail.title + index}
              title={cocktail.title}
              price={cocktail.price}
              tags={cocktail.tags}
            />
          ))}
        </div>
      </div>
    </div>
    {/* <div style={{ marginTop: '15px' }}>
      <button type="button" className="custom__button">
        View More
      </button>
    </div> */}

    {/* SPECIAL MENU FOOD */}

    <div className="app__specialMenu-menu">
      {/* SPECIAL MENU LEFT SIDE */}

      <div className="app__specialMenu-menu_entries app__specialMenu-section">
        <p className="app__specialMenu-menu_heading">Entrées</p>
        <div className="app__specialMenu_menu_items">
          {data.appetizers.map((appetizer, index) => (
            <MenuItem
              key={appetizer.title + index}
              title={appetizer.title}
              price={appetizer.price}
              tags={appetizer.tags}
            />
          ))}
        </div>
        <img src={images.appetizer} alt="Entrée" />
      </div>
      {/* SPECIAL MENU MIDDLE */}
      <div className="app__specialMenu-menu_main-course app__specialMenu-section">
        <p className="app__specialMenu-menu_heading">Plats Principaux</p>
        <div className="app__specialMenu_menu_items">
          {data.mainCourses.map((mainCourse, index) => (
            <MenuItem
              key={mainCourse.title + index}
              title={mainCourse.title}
              price={mainCourse.price}
              tags={mainCourse.tags}
            />
          ))}
        </div>
        <img src={images.mainCourse} alt="Plat Principal" />
      </div>
      {/* SPECIAL MENU RIGHT SIDE */}

      <div className="app__specialMenu-menu_dessert app__specialMenu-section">
        <p className="app__specialMenu-menu_heading">Desserts</p>
        <div className="app__specialMenu_menu_items">
          {data.desserts.map((dessert, index) => (
            <MenuItem
              key={dessert.title + index}
              title={dessert.title}
              price={dessert.price}
              tags={dessert.tags}
            />
          ))}
          <br />
          <br />
          <br />
          <br />
        </div>
        <img src={images.dessert} alt="Dessert" />
      </div>
    </div>
    {/* <div style={{ marginTop: '15px' }}>
      <button type="button" className="custom__button">
        View More
      </button>
    </div> */}
  </div>
);

export default SpecialMenu;
