import React from 'react';

import './Newsletter.css';
import SubHeading from '../SubHeading/SubHeading';

const Newsletter = () => (
  <div className="app__newsletter">
    <div className="app__newsletter-heading">
      <SubHeading title="Newsletter" />
      <h1 className="headtext__cormorant">Abonnez-vous à notre newsletter</h1>
      <p className="p__opensans">
        Et ne manquez jamais les dernières nouveautés
      </p>
    </div>
    <div className="app__newsletter-input flex__center">
      <input type="email" placeholder="Entrez votre adresse e-mail" />
      <button className="custom__button">S'abonner</button>
    </div>
  </div>
);

export default Newsletter;
