import React from 'react';
import { images } from '../../constants';
import './Header.css';
import { SubHeading } from '../../components';

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading title="Découvrez de nouvelles saveurs" />
      <h1 className="app__header-h1">La clé de la gastronomie</h1>
      <p className="p__opensans" style={{ margin: '2rem 0' }}>
        Guich & Gourmet vous propose une cuisine authentique et créative, élaborée
        à partir des meilleurs produits de saison. Dans une ambiance
        chaleureuse, notre équipe vous invite à savourer des plats raffinés et à
        vivre une expérience culinaire unique, que ce soit pour un repas en
        tête-à-tête, en famille ou entre amis.
      </p>
      <button type="button" className="custom__button">
        <a href="#menu"> Voir Menu</a>
      </button>
    </div>
    <div className="app__wrapper_img">
      <img src={images.welcome} alt="header img" />
    </div>
  </div>
);

export default Header;
